export const ROUTES = {
    HOME: '/',
    AUTH: {
        LOGIN: '/auth/login',
        LOGIN_WITH_QR: '/auth/login/qr',
        PAIR: '/auth/pair/:deviceCode',
        SUCCESSFUL_PAIR: '/auth/successful-pair',
    },
    MEDIAS: {
        PLAY: '/play',
        MOVIES: '/medias/movies',
        MOVIE_DETAIL: '/medias/movies/:id',
        SERIES: '/medias/series',
        SERIES_DETAIL: '/medias/series/:id',
        SEARCH: '/medias/search',
        LIVE: '/medias/live',
        LIVE_CHANNEL: '/medias/live/:channelId',
        LIST: '/medias/list',
    },
    INFO: {
        PRIVACY_POLICY: '/info/privacy-policy',
        TERMS_OF_SERVICE: '/info/terms-of-service',
        DISCLAIMER: '/info/disclaimer',
        DELETE_ACCOUNT: '/info/account-deletion',
    },
} as const;
