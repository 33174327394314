import { useState } from 'react';
import { Film, Home, LogOutIcon, Menu, Search, Tv, X } from 'lucide-react';
import { NavLink } from 'react-router-dom';
import Logo from '@/assets/images/logo/logo.png';
import { useLoginRedirect } from '@/hooks/useLoginRedirect';
import { ROUTES } from '@/providers/RouterProvider/routes';
import { logout } from '@/services/auth';
import styles from './Header.module.css';

function Header() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const loginRedirect = useLoginRedirect();

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleLogout = () => {
        logout();
        loginRedirect();
    };

    return (
        <header className={styles.header}>
            <div className={styles.container}>
                <button
                    type="button"
                    className={styles.menuButton}
                    onClick={toggleMenu}
                >
                    {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
                </button>

                <NavLink to={ROUTES.HOME} className={styles.logo}>
                    <img src={Logo} alt="Logo" />
                </NavLink>

                <nav
                    className={`${styles.nav} ${isMenuOpen ? styles.active : ''}`}
                >
                    <button
                        type="button"
                        className={styles.closeButton}
                        onClick={toggleMenu}
                    >
                        <X size={24} />
                    </button>

                    <NavLink
                        to={ROUTES.HOME}
                        exact
                        activeClassName={styles.navLinkActive}
                        className={styles.navLink}
                        onClick={() => setIsMenuOpen(false)}
                    >
                        <Home size={18} />
                        <span>خانه</span>
                    </NavLink>

                    <NavLink
                        to={ROUTES.MEDIAS.MOVIES}
                        activeClassName={styles.navLinkActive}
                        className={styles.navLink}
                        onClick={() => setIsMenuOpen(false)}
                    >
                        <Film size={18} />
                        <span>فیلم</span>
                    </NavLink>

                    <NavLink
                        to={ROUTES.MEDIAS.SERIES}
                        activeClassName={styles.navLinkActive}
                        className={styles.navLink}
                        onClick={() => setIsMenuOpen(false)}
                    >
                        <Tv size={18} />
                        <span>سریال</span>
                    </NavLink>

                    <NavLink
                        to={ROUTES.MEDIAS.LIVE}
                        activeClassName={styles.navLinkActive}
                        className={styles.navLink}
                        onClick={() => setIsMenuOpen(false)}
                    >
                        <Tv size={18} />
                        <span>پخش زنده</span>
                    </NavLink>

                    <NavLink
                        to={ROUTES.MEDIAS.SEARCH}
                        activeClassName={styles.navLinkActive}
                        className={styles.navLink}
                    >
                        <Search size={18} />
                        <span>جستجو</span>
                    </NavLink>
                </nav>

                <div className={styles.userSection}>
                    <button
                        type="button"
                        className={styles.logOutButton}
                        onClick={handleLogout}
                    >
                        <LogOutIcon size={19} />
                        <span>خروج</span>
                    </button>
                </div>
            </div>
        </header>
    );
}

export default Header;
