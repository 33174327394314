import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ROUTES } from '@/providers/RouterProvider/routes';
import { getLocalItem } from '@/utils/localStorage.util';

function UnauthorizedLayout({ children }: { children?: React.ReactNode }) {
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        const searchParameters = new URLSearchParams(location.search);
        const deviceCode = searchParameters.get('device_code');
        const isAuthenticated = !!getLocalItem<string>('accessToken');

        // Check if the pathname contains '/info/'
        const shouldRedirect = !location.pathname.includes('/info/');

        if (isAuthenticated && !deviceCode && shouldRedirect) {
            history.replace(ROUTES.HOME);
        }
    }, [history, location.search, location.pathname]);

    return children;
}

export default UnauthorizedLayout;
